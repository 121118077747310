import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import Empty from '../../../Components/Empty';
import ProductItem from '../../../Components/SnowOwl/ProductItem';
import * as AppActions from '../../../AppActions';
import ArticleItem from '../../../Components/SnowOwl/ArticleItem';

function ProductGrid(props) {
  const {onItemClick, prefixPath, products} = props;
  const [dimension] = useOutlet('dimension');
  const mobile = dimension.rwd === 'mobile';
  const [landing] = useOutlet('landing');
  const [user] = useOutlet('user');
  if (products.length <= 0) {
    return <Empty />;
  }

  if (prefixPath.indexOf('products') >= 0) {
    const itemPerRow = 4;
    const containerWidth = 765;
    return (
      <ProductGridWrapper
        itemPerRow={itemPerRow}
        containerWidth={containerWidth}
        mobile={mobile}>
        {products.map((product) => {
          const showPrice = !!user || !landing.hide_price_for_user_not_login;
          return (
            <ProductItem
              showPrice={showPrice}
              containerWidth={containerWidth}
              itemPerRow={itemPerRow}
              key={product.id}
              title={product.name}
              subtitle={product.blockchain}
              price={product.price}
              image={product.images?.[0].expected_url || ''}
              onClick={async () => {
                await AppActions.navigate(`/product?id=${product.id}`);
              }}
            />
          );
        })}
        {new Array(itemPerRow).fill('product').map((item, key) => (
          <div className="filler" key={key} />
        ))}
      </ProductGridWrapper>
    );
  } else if (prefixPath.indexOf('articles') >= 0) {
    return (
      <ArticleGridWrapper mobile={mobile}>
        {products.map((product, i) => (
          <ArticleItem
            key={i}
            image={product.image}
            title={product.title}
            description={product.outline}
            date={product.created}
            onClick={(evt) => onItemClick(product, evt)}
          />
        ))}
      </ArticleGridWrapper>
    );
  }
}

const ProductGridWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: 20px;
  & > .filler {
    height: 1px;
    width: ${({containerWidth, itemPerRow}) =>
      `calc((${containerWidth}px / ${itemPerRow}) - (${
        itemPerRow - 1
      } * 5px))`};
    margin: 5px;
  }

  @media screen and (max-width: 767px) {
    justify-content: center;
    margin-left: unset;
  }
`;

const ArticleGridWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export default ProductGrid;
