import React from 'react';
import * as AppActions from '../../AppActions';
import ProductGrid from '../../Templates/ProductList/ProductGrid';
import BrowseHeader from '../../Components/BrowseHeader';

export function getExtraProductListProps(props) {
  let topFilter = hasCustomTopFilter(props.location.pathname);
  let filterMenuType = getCustomFilterMenuType(props.location.pathname);

  let onCustomClick = async (item) => {
    if (props.location.pathname.indexOf('/products') > -1) {
      await AppActions.navigate(`/product?id=${item.id}`, {loading: true});
    } else if (props.location.pathname.indexOf('/articles') > -1) {
      await AppActions.navigate(`/article?id=${item.id}`, {loading: true});
    }
  };

  function renderCustomSection({route, sectionId, params}) {
    if (route === '/products' && sectionId === 'A') {
      return (
        <>
          <BrowseHeader route={route} cat={params.cat} />
          <git artist={params.cat} />
        </>
      );
    }

    if (sectionId === 'A') {
      return <BrowseHeader route={route} cat={params.cat} />;
    }
    return null;
  }

  return {
    ...props,
    ProductGrid,
    topFilter,
    filterMenuType,
    onCustomClick,
    renderCustomSection,
  };
}

export function getExtraProductDetailPage(props) {
  let filterMenuType = getCustomFilterMenuType(props.location.pathname);

  function renderCustomSection(sectionId, params) {
    return null;
  }

  return {
    filterMenuType,
    renderCustomSection,
  };
}

export function getCustomFilterMenuType(pathname) {
  if (pathname.indexOf('/product') > -1) {
    return 'L2';
  } else if (pathname.indexOf('/article') > -1) {
    return 'L1';
  }
  return null;
}

export function hasCustomTopFilter(pathname) {
  if (pathname.indexOf('/article') > -1) {
    return true;
  }
  return false;
}
