import styled from 'styled-components';
import React from 'react';
import moment from 'moment';

const ArticleItem = ({title, description, date, image, onClick}) => {
  return (
    <StyledArticleItem onClick={onClick}>
      <img src={image} alt="article-thumbnail" />
      <div className="info">
        <div className="caption">
          <h1>{title} </h1>
          <h2>{description}</h2>
        </div>
        <small>{moment(date).format('YYYY-MM-DD HH:mm:ss')}</small>
      </div>
    </StyledArticleItem>
  );
};
const StyledArticleItem = styled.div`
  width: 100%;
  height: 315px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  margin-bottom: 20px;
  border: 1px solid var(--snow-owl-theme-button-border-color);
  :hover {
    box-shadow: 0 5px 20px 1px #888;
  }
  & > .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 50px;
    & * {
      color: var(--snow-owl-theme-font-color);
    }
    & > .caption {
      & > h1 {
        font-weight: 900;
        margin-bottom: 10px;
        font-size: 28px;
        line-height: 28px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
      & > h2 {
        text-align: left;
        font-weight: 500;
        font-size: 14px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
      }
    }
    & > small {
      font-size: 14px;
      align-self: flex-end;
    }
  }
  & > img {
    height: 100%;
    aspect-ratio: 1;
    border-radius: 10px 0 0 10px;
  }
  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    width: 48%;
    height: unset;
    & > .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px;
      min-height: 250px;
    }
    & > img {
      height: unset;
      width: 100%;
      aspect-ratio: 1;
      border-radius: 10px 10px 0 0;
    }
  }

  @media screen and (max-width: 575px) {
    width: 100%;
    & > .info {
      min-height: 250px;
    }
    & > img {
    }
  }
`;

export default ArticleItem;
