import React from 'react';
import styled from 'styled-components';

const StyledLoader = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  & .loader {
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const ImagePlaceholder = () => {
  return (
    <StyledLoader>
      <img src="/assets/icon.png" alt="loader" className="loader" />
    </StyledLoader>
  );
};

export default ImagePlaceholder;
