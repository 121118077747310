import styled from 'styled-components';
import React, {useState} from 'react';
import ImagePlaceholder from './ImagePlaceholder';

const ProductItem = (props) => {
  const {
    subtitle,
    title,
    price,
    image,
    containerWidth = 1200,
    itemPerRow = 5,
    onClick,
    showPrice,
  } = props;
  const [loaded, setLoaded] = useState(false);

  return (
    <StyledProductItem
      containerWidth={containerWidth}
      itemPerRow={itemPerRow}
      onClick={onClick}>
      <div className="top">
        {!loaded && <ImagePlaceholder />}
        <img
          className="product-img"
          src={image}
          alt="product"
          loading="lazy"
          style={{visibility: loaded ? 'visible' : 'hidden'}}
          onLoad={() => {
            setLoaded(true);
          }}
        />
      </div>
      <div className="info">
        <div className="header">
          <div className="subtitle">{subtitle}</div>
          <div className="title">{title}</div>
        </div>
        {showPrice && <div className="price">NTD ${price}</div>}
        {!showPrice && <div className="price">NTD 登入即見</div>}
      </div>
    </StyledProductItem>
  );
};

const StyledProductItem = styled.div`
  width: ${({containerWidth, itemPerRow}) =>
    `calc((${containerWidth}px / ${itemPerRow}) - (${itemPerRow - 1} * 5px))`};
  border-radius: 10px;
  border: 1px solid var(--snow-owl-theme-button-border-color);
  :hover {
    box-shadow: 0 5px 20px 1px #888;
  }
  cursor: pointer;
  margin: 5px;
  & > .top {
    position: relative;
    & > img.product-img {
      width: 100%;
      aspect-ratio: 1;
      border-radius: 10px 10px 0 0;
    }
  }
  & > .info {
    padding: 5px 0 12px 0;
    height: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    & > .header {
      display: flex;
      flex-direction: column;
      text-align: center;
      & > .subtitle {
        font-size: 11px;
        color: #7d838b;
        letter-spacing: 0.39px;
        line-height: 20px;
        font-weight: 400;
      }
      & > .title {
        font-size: 14px;
        color: var(--snow-owl-theme-font-color);
        letter-spacing: 0.5px;
        line-height: 20px;
        font-weight: 500;
      }
    }
    & > .price {
      font-size: 11px;
      color: var(--snow-owl-theme-font-color);
      letter-spacing: 0.39px;
      line-height: 20px;
      font-weight: 700;
    }
  }
`;

export default ProductItem;
